import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from './layout';

import { Query } from '../../types/graphql-types';
import { BlogYearPageLinks } from '../ui/blog-yearpage-links';
import { BlogPosts } from '../ui/blog-posts';
import { BlogYearMeta } from '../../pages/blog';

const BlogYearLayout = ({
  data,
  pageContext,
}: {
  data: Query;
  pageContext: { year: number; years: BlogYearMeta[] };
}) => {
  const { year, years } = pageContext;

  return (
    <main>
      <Layout pageTitle="黄金虫ブログ">
        <h2 className="text-center text-xl">{year}</h2>
        <BlogPosts nodes={data.allMdx.nodes}>
          <BlogYearPageLinks years={years}></BlogYearPageLinks>
        </BlogPosts>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query BlogYearLayoutQuery($gte: Date, $lt: Date) {
    allMdx(filter: { frontmatter: { date: { gte: $gte, lt: $lt } } }) {
      nodes {
        frontmatter {
          date
          slug
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
  }
`;

export default BlogYearLayout;
